import { Outlet } from "react-router-dom"
import Navbar from "./Navbar"
import Footer from "./Footer"

const Layout = (props) => {
  return (
    <>
      <Navbar></Navbar>
      <Outlet/>
      <Footer></Footer>
    </>
  )
}

export default Layout