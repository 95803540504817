import { useScreenHeight } from "../../utils/windowSize"
import { useGlobal } from "reactn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faBookQuran, faHandHoldingHeart, faHandshake } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

const DownArrow = (props) => {
  return (
    <div className="relative">
      <div
        className="flex content-center justify-center flex-end items-center"
        style={{
          width: `${props.tailLength}px`,
        }}
      >
        <div
          className="bg-gold rounded"
          style={{
            width: `${props.thickness}px`,
            height: `${props.height}px`,
          }}
        ></div>
      </div>
      <div
        className={`border border-gold`}
        style={{
          position: "absolute",
          bottom: "0px",
          width: `${props.tailLength}px`,
          height: `${props.tailLength}px`,
          borderWidth: `0px ${props.thickness}px ${props.thickness}px 0px`,
          borderRadius: `${props.thickness}px`,
          transform: "rotate(45deg)",
        }}>
      </div>
    </div>
  )
}

const Overview = (props) => {
  const [global] = useGlobal()
  const screenHeight = useScreenHeight()
  return (
    <div>
      {/* -------------------- Block 1 -------------------- */}
      <div
        className="relative overflow-hidden bg-dark-green px-10 mobile:px-20 md:px-40 py-20"
        style={{ height: screenHeight - global.navbarHeight }}>
        <div
          className="hidden tablet:block absolute bg-gold w-[340px] h-[170px] opacity-80"
          style={{
            borderRadius: '50%',
            bottom: '-95px',
            right: '600px',
          }}
        >
        </div>
        <div
          className="hidden tablet:block absolute bg-gold w-[430px] h-[280px] opacity-90"
          style={{
            borderRadius: '50%',
            bottom: '-140px',
            right: '350px',
          }}
        >
        </div>
        <div
          className="hidden tablet:block absolute bg-gold w-[700px] h-[380px]"
          style={{
            borderRadius: '50%',
            bottom: '-140px',
            right: '-240px',
          }}
        >
        </div>
        <div className="flex flex-col justify-center h-full">
          <div className="text-left w-full tablet:w-1/2">
            <h1 className="text-gold text-4xl lg:text-5xl monomaniac-one mb-5">
              About Us
            </h1>
            <p className="text-white text-xl lg:text-2xl jet-brains-mono font-medium mb-20">
              Bridging communities with compassion and Islamic education
            </p>
            <DownArrow
              height={50}
              thickness={2}
              tailLength={10}
              color={'gold'}
            />
          </div>
        </div>
      </div>

      {/* -------------------- Block 2 -------------------- */}
      <div className="bg-white px-10 mobile:px-20 md:px-40 py-20">
        <div className="text-left mb-8 sm:mb-20">
          <h1 className="text-gold text-4xl monomaniac-one mb-5">
            Who We Are
          </h1>
          <p className="text-black text-sm md:text-lg jet-brains-mono">
            We are a diverse group of individuals committed to making a meaningful difference
            in the lives of individuals and communities. We have long-term visions on how to
            solve problems that face our community on a daily basis. To solve these problems,
            we look towards <b>innovation, holistic solutions,</b> and fostering a <b>sense of community</b> to
            achieve positive outcomes and real results.
          </p>
        </div>

        <div className="flex flex-col sm:flex-row sm:gap-12">
          <div className="text-left mb-8 sm:mb-0">
            <h1 className="text-gold text-4xl monomaniac-one mb-5">
              Our Mission
            </h1>
            <p className="text-black text-sm md:text-lg jet-brains-mono">
              To empower our community through the provision of Islamic education and social
              services while fostering unity, compassion, and advocacy, all guided by the principles
              of truth and righteousness.
            </p>
          </div>
          <div className="text-left">
            <h1 className="text-gold text-4xl monomaniac-one mb-5">
              Our Values
            </h1>
            <p className="text-black text-sm md:text-lg jet-brains-mono">
              We seek to achieve a space where every individual has the opportunity to live with
              dignity, achieve their full potential, and contribute positively to their community.
              We aim to exemplify sustainability and empowerment for all.
            </p>
          </div>
        </div>
      </div>

      {/* -------------------- Block 3 -------------------- */}
      <div id="who-we-are" className="flex flex-col gap-8 bg-dark-green px-10 mobile:px-20 md:px-40 py-20 text-left">
        <h1 className="text-gold text-4xl monomaniac-one">What We Do</h1>

        <div className="flex flex-row items-stretch gap-5">
          <FontAwesomeIcon className="hidden mobile:block border rounded-full w-[40px] h-[40px] p-6 text-gold text-5xl" icon={faBookQuran} />

          <div className="flex flex-col gap-2">
            <h1 className="text-gold text-xl monomaniac-one">Islamic Education</h1>
            <p className="text-white text-sm jet-brains-mono">
              We seek to provide opportunities for community engagement through volunteer opportunities, youth mentorship,
              and social events. Our organization is for everyone and we want those involved to feel a sense of ownership
              towards our work.
            </p>
          </div>
        </div>

        <div className="flex flex-row items-stretch gap-5">
          <FontAwesomeIcon className="hidden mobile:block border rounded-full w-[40px] h-[40px] p-6 text-gold text-5xl" icon={faHandHoldingHeart} />

          <div className="flex flex-col gap-2">
            <h1 className="text-gold text-xl monomaniac-one">Social Services</h1>
            <p className="text-white text-sm jet-brains-mono">
              Every community has a diverse set of needs and we seek to alleviate the most critical needs through programs
              for social services. These will address various social determinants of health to provide positive outcomes for
              the community.
            </p>
          </div>
        </div>

        <div className="flex flex-row items-stretch gap-5">
          <FontAwesomeIcon className="hidden mobile:block border rounded-full w-[40px] h-[40px] p-6 text-gold text-5xl" icon={faHandshake} />

          <div className="flex flex-col gap-2">
            <h1 className="text-gold text-xl monomaniac-one">Community Involvement</h1>
            <p className="text-white text-sm jet-brains-mono">
              We seek to provide opportunities for community engagement through volunteer opportunities, youth mentorship,
              and social events. Our organization is for everyone and we want those involved to feel a sense of ownership
              towards our work.
            </p>
          </div>
        </div>
      </div>


      {/* -------------------- Block 4 -------------------- */}
      <div className="flex flex-col gap-5 bg-white px-10 mobile:px-20 md:px-40 py-20 text-left">
        <h1 className="text-gold text-4xl monomaniac-one">Our Values</h1>
        <p className="text-black text-sm md:text-lg jet-brains-mono">
          These values exemplify what we stand for as an organization and how we aim to represent ourselves through our
          work within this community. This is what holds us accountable and guides our initiatives and decision-making processes.
        </p>

        <ol className="grid grid-cols-2 grid-rows-2 grid-flow-col gap-x-8 gap-y-5 list-decimal list-inside jet-brains-mono text-sm md:text-lg font-bold">
          <li>Transparency - <span className="font-normal">easily accessible information regarding all aspects of our activities including projects, finances, planning and goals, etc.</span></li>
          <li>Innovation - <span className="font-normal">encouraging innovative and dynamic practices to address community challenges and continually improve programs and services</span></li>
          <li>Balance - <span className="font-normal">maintain activities that remain free from any external agendas or influences to provide balanced services.</span></li>
          <li>Holistic Methodology - <span className="font-normal">creating an environment which promotes covering all aspects of life to promote better outcomes for those seeking services</span></li>
        </ol>
      </div>

      {/* -------------------- Block 5 -------------------- */}
      <div className="flex flex-col items-center bg-gold px-10 mobile:px-20 md:px-40 py-4">
        <h1 className="text-black text-4xl monomaniac-one mb-5">
          Reach out to Us!
        </h1>
        <div className="text-black flex w-full justify-evenly text-4xl lg:text-5xl tablet:w-3/4 jet-brains-mono font-medium">
          <a target="_blank" href="https://www.facebook.com/people/Al-Haqq-Foundation/61558335982609/"><FontAwesomeIcon icon={faFacebook} /></a>
          <a target="_blank" href="https://www.instagram.com/alhaqq_foundation/"><FontAwesomeIcon icon={faInstagram} /></a>
          <a target="_blank" href="https://www.linkedin.com/company/alhaqqfoundation/"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href="mailto:admin@alhaqqfoundation.ca"><FontAwesomeIcon icon={faEnvelope} /></a>
        </div>
      </div>
    </div>
  )
}

export default Overview