import { useEffect, useState } from "reactn"
import { useParams } from "react-router-dom"
import client from "../../client"

const Card = (props) => {
  return (
    <button
      className="flex flex-col w-[250px] xl:w-full xl:flex-row text-left shadow shadow-[4px_4px_10px_0px_rgba(0,0,0,0.3)] rounded-lg xl:mb-7
      hover:shadow-[2px_2px_5px_0px_rgba(0,0,0,0.3)] transition-all ease-in-out duration-300
      active:shadow-[0px_0px_1px_0px_rgba(0,0,0,0.3)] active:transition-none"
    >
      <div
        className="relative rounded-lg overflow-hidden w-full xl:w-[600px] h-[125px] xl:h-[200px]"
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundPosition: "center",
          backgroundSize: "110% auto"
        }}
      ></div>
      <div className="w-full px-4 xl:px-8 py-2">
        <h1 className="text-gold text-2xl xl:text-4xl monomaniac-one mb-2">{props.title}</h1>
        <p className="text-black text-sm jet-brains-mono pb-2">{props.description}</p>
      </div>
    </button>
  )
}

const Programs = (props) => {
  const [data, setData] = useState(false);
  const [exists, setExists] = useState(false);
  const { slug } = useParams()

  useEffect(() => {
    (async () => {
      const data = await client.fetch(
        `*[_type == "program" && slug.current == "${slug}"] {
        title,
        description,
        body,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
      )
      if (data.length > 0) {
        setData(data[0])
        setExists(true)
      } else {
        setData(true)
        setExists(false)
      }
    })()
  }, [slug])

  return (
    <div>
      {/* -------------------- Loading... -------------------- */}

      {!data && (
        <div className="px-10 mobile:px-20 md:px-40 py-20 text-left h-[55vh]">
          <h1 className="text-black text-4xl monomaniac-one mb-5">Loading...</h1>
        </div>
      )}
      {data && !exists && (
        <div className="px-10 mobile:px-20 md:px-40 py-20 text-left h-[55vh]">
          <h1 className="text-black text-4xl monomaniac-one mb-5">Page Does Not Exist</h1>
        </div>
      )}
      {/* -------------------- Block 1 -------------------- */}
      {data && exists && (
        <div className="px-10 mobile:px-20 md:px-40 py-20 text-left">
          <h1 className="text-black text-4xl monomaniac-one mb-5">Programs</h1>
          {data.body.map((content, i) => {
            if (content._type === "block") {
              return (
                <div key={i}>{content.children[0].text}</div>
              )
            } else {
              return undefined
            }
          })}
        </div>
      )}
    </div>
  )
}

export default Programs