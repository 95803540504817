
const PageNotFound = (props) => {
  return (
    <div>
      <div className="bg-dark-green h-96 px-40 py-56">
        <h1
          className="text-white text-center"
          style={{
            fontFamily: "Monomaniac One",
            fontSize: "30px",
          }}
        >
          404 Error: Page Not Found
        </h1>
      </div>
      {/* <div className="bg-white h-96">
        
      </div> */}
    </div>
  )
}

export default PageNotFound