import { useEffect, useState } from "react"


const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  return screenWidth
}

const useScreenHeight = () => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight)
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, [])
  return screenHeight
}

const screenSizes = {
  'sm': 640,
  'md': 768,
  'tablet': 874,
  'lg': 1024,
  'xl': 1280,
  '2xl': 1536,
}

export {useScreenWidth, useScreenHeight, screenSizes}